body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid-header {
  background-color: #3f51b540;
}

.penalty {
  background-color: #FFEEAA;
}

.table-row-odd {
  background-color: #F2F2F2;
}

.table-row-even {
  background-color: white;
}

.needs-yahoo-sync {
  color: red;
}

.proposed-trade {
  background-color: lightgreen;
}

.is-rfa {
  background-color: lightblue;
}

.picked-to-win {
  background-color: lightgreen;
}

.picked-to-lose {
  background-color: lightcoral;
}

.dirty {
  background-color: #f0808040;
}

.clean {
  background-color: #f0808040;
}


.is-self {
  background-color: #90ee9040;
}

.manager-color-1 {
  background-color: #4e79a7;
}

.manager-color-2 {
  background-color: #f28e2c;
}

.manager-color-3 {
  background-color: #e15759;
}

.manager-color-4 {
  background-color: #76b7b2;
}

.manager-color-5 {
  background-color: #59a14f;
}

.manager-color-6 {
  background-color: #edc949;
}

.manager-color-7 {
  background-color: #af7aa1;
}

.manager-color-8 {
  background-color: #ff9da7;
}

.manager-color-9 {
  background-color: #9c755f;
}

.manager-color-10 {
  background-color: #bab0ab;
}

.manager-color-1-light {
  background-color: #4e79a725;
}

.manager-color-2-light {
  background-color: #f28e2c25;
}

.manager-color-3-light {
  background-color: #e1575925;
}

.manager-color-4-light {
  background-color: #76b7b225;
}

.manager-color-5-light {
  background-color: #59a14f25;
}

.manager-color-6-light {
  background-color: #edc94925;
}

.manager-color-7-light {
  background-color: #af7aa125;
}

.manager-color-8-light {
  background-color: #ff9da725;
}

.manager-color-9-light {
  background-color: #9c755f25;
}

.manager-color-10-light {
  background-color: #bab0ab25;
}
